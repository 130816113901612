<template>
  <section>
    <div class="content-row">
      <aRow :gutter="20">
        <aCol :span="24">
          <aRow class="infos" :gutter="24">
            <aCol :span="6">
              <span class="label">Serviço:</span>
              {{ product.type }}
            </aCol>

            <aCol :span="6">
              <span class="label">Localizador:</span>
              {{ product.locator }}
            </aCol>

            <aCol :span="6">
              <span class="label">Check-in:</span>
              {{ product.checkin | formatMultiDates }}
            </aCol>

            <aCol :span="6">
              <span class="label">Check-out:</span>
              {{ product.checkout | formatMultiDates }}
            </aCol>

            <aCol class="mb-5 mt-20" :span="24"><h4>VIAJANTES</h4> </aCol>

            <aCol class="travellers upper" :span="24">
              <aRow
                class="traveller"
                v-for="(traveller, index) in product.serviceTravellers"
                :key="index"
              >
                <aCol :span="18">
                  {{ traveller.name }}
                </aCol>

                <aCol :span="6">
                  {{ formatMultiDates(traveller.birthday) }}
                </aCol>
              </aRow>
            </aCol>
          </aRow>
        </aCol>
      </aRow>

      <div class="form" v-if="type === 'edit'">
        <ContractCancelServiceForm
          class="mt-30"
          :product="product"
          :tempContract="tempContract"
          @onChangeCancelProductData="onChangeCancelProductData"
        />
      </div>
    </div>
  </section>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";
import ContractCancelServiceForm from "./ContractCancelServiceForm.vue";

export default {
  name: "ContractModalServiceRow",
  props: {
    product: Object,
    tempContract: Object,
    allContractData: Object,
    type: String,
  },
  components: { ContractCancelServiceForm },
  mixins: [formatThings],
  methods: {
    onChangeCancelProductData(tempContract) {
      this.$emit("onChangeCancelProductData", tempContract);
    },
  },
};
</script>

<style lang="sass" scoped>
.content-row
  background: #FFF
  padding: 10px
  border: 1px solid #ddd !important
  margin: 0 0 10px
  border-radius: 6px
  font-weight: 500
  .form
    background: #f6f6f6
    padding: 10px 20px 0
    margin: 20px -10px -11px -10px
    border-top: 1px solid #ddd
    border-bottom: 1px solid #ddd
  .infos
    font-size: 12px
    color: #333
  .label
    display: block
    font-size: 11px
    font-weight: 500
    color: #aaa
  h4
    font-size: 10px !important
    font-weight: 600
    color: #be4178
  .travellers
    .traveller
      padding: 0 0 8px 0
      margin: 0 0 8px
      border-bottom: 1px solid #eee
      font-size: 12px
    .traveller:last-child
      border: 0
      padding-bottom:0
</style>
