var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aFormItem',{staticClass:"hf-wrapper hf-input-wrapper"},[(_vm.label)?_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_vm._v(" "),(_vm.description)?_c('div',{staticClass:"description"},[_vm._v(_vm._s(_vm.description))]):_vm._e(),_vm._v(" "),_c('aInput',{directives:[{name:"currency",rawName:"v-currency",value:({
      currency: {
        prefix: '',
        suffix: '',
      },
    }),expression:"{\n      currency: {\n        prefix: '',\n        suffix: '',\n      },\n    }"},{name:"decorator",rawName:"v-decorator",value:([
      _vm.fieldKey,
      {
        initialValue: _vm.initialValue,
        rules: _vm.validationRules,
      },
    ]),expression:"[\n      fieldKey,\n      {\n        initialValue,\n        rules: validationRules,\n      },\n    ]"}],staticClass:"hf-input",class:_vm.fieldClass,attrs:{"type":_vm.type,"placeholder":_vm.placeholder || 'Insira'},on:{"blur":_vm.onBlur,"change":_vm.onChange},scopedSlots:_vm._u([(_vm.hasPrefixContent)?{key:"prefix",fn:function(){return [(!_vm.preIcon)?_vm._t("prefix"):_vm._e(),_vm._v(" "),(_vm.preIcon)?_c('a-icon',{attrs:{"type":"field-svg"}}):_vm._e()]},proxy:true}:null,(_vm.hasSuffixContent)?{key:"suffix",fn:function(){return [_vm._t("suffix")]},proxy:true}:null],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }