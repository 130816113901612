<template>
  <section>
    <aRow>
      <aCol :span="24">
        <aFormItem class="payments-options">
          <aCheckboxGroup
            v-decorator="[
              `payment_methods${keySuffix}`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            @change="onPaymentChange"
          >
            <aCheckbox
              v-for="(method, index) in paymentMethodsOptions"
              :key="index"
              :value="method.value"
              :disabled="method.show"
            >
              {{ method.label }}
            </aCheckbox>
          </aCheckboxGroup>
        </aFormItem>
      </aCol>
    </aRow>

    <aRow class="status-bar">
      <aCol :span="24">
        <strong>VALOR TOTAL</strong>
        <aTag class="ml-10" :color="true ? 'red' : 'green'">
          R$ {{ totalFees() | formatPrice }}
        </aTag>

        <span class="equality">
          <span v-if="true">
            <aIcon type="swap" />
          </span>
          <span v-if="!true">
            <aIcon type="swap-right" />
          </span>
        </span>

        <aTag class="ml-10" color="blue"> R$ {{ 0 | formatPrice }} </aTag>
      </aCol>
    </aRow>

    <aAlert
      class="mb-20 mt-10 f11"
      type="error"
      v-if="true"
      message="Os valores somados não se equivalem ao total. Verique os valores preenchidos."
      banner
    />
<!--
    {{ paymentMethods }} -->

    <div v-if="false">
      <div
        v-if="paymentMethods.length > 0"
        class="portal-tabs horizontal payment-tabs"
      >
        <ContractPaymentMethodsValuesPanel
          :paymentMethods="paymentMethods"
          :activeTab="activeTab"
          :tempContract="tempContract"
          :third_party_payment_rows="third_party_payment_rows"
          :paymentCardTotal="paymentCardTotal"
          :creditCardTotal="creditCardTotal"
          :paymentLinkTotal="paymentLinkTotal"
          @portalActiveTab="portalActiveTab"
        />

        <div class="tabs-content">
          <component
            v-for="tab in filteredTabs"
            :is="tab.component"
            :key="tab.name"
            v-bind="tab.props"
            @verifyTotal="verifyTotal"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { parse } from "vue-currency-input";
parse;
import formatThings from "@/mixins/general/formatThings.js";
import contractPaymentMethodsMixins from "@/components/contracts/mixins/contractPaymentMethodsMixins";

import PaymentCardCollapse from "../collapses/PaymentCardCollapse.vue";
import ClientThirdPartyForm from "../collapses/ClientThirdPartyForm.vue";

import PaymentLinkTab from "../tabs/PaymentLinkTab.vue";
import CreditCardTab from "../tabs/CreditCardTab.vue";
import PaymentCardTab from "../tabs/PaymentCardTab.vue";
import BillingTicketTab from "../tabs/BillingTicketTab.vue";
import CustomPaymentTab from "../tabs/CustomPaymentTab.vue";
import BankTransferTab from "../tabs/BankTransferTab.vue";
import ContractPaymentMethodsValuesPanel from "../sections/ContractPaymentMethodsValuesPanel.vue";

export default {
  name: "ContractPaymentsReceivable",
  props: {
    form: Object,
    tempContract: Object,
    contract: Object,
    keyPrefix: String,
    keySuffix: String,
    baseId: String,
    baseFinancesId: String,
  },
  components: {
    ClientThirdPartyForm,
    PaymentLinkTab,
    CreditCardTab,
    PaymentCardTab,
    PaymentCardCollapse,
    BillingTicketTab,
    CustomPaymentTab,
    BankTransferTab,
    ContractPaymentMethodsValuesPanel,
  },
  mixins: [formatThings, contractPaymentMethodsMixins],
  data() {
    return {
      paymentMethods: [],
      activeTab: "",
      third_party_payment_rows: [],
      filteredTabs: [
        {
          name: "billing-ticket",
          component: BillingTicketTab,
          props: this.tabProps,
        },
        { name: "credit-card", component: CreditCardTab, props: this.tabProps },
        {
          name: "payment-card",
          component: PaymentCardTab,
          props: this.tabProps,
        },
        {
          name: "payment-link",
          component: PaymentLinkTab,
          props: this.tabProps,
        },
        {
          name: "custom-payment",
          component: CustomPaymentTab,
          props: this.tabProps,
        },
        {
          name: "bank-transfer",
          component: BankTransferTab,
          props: this.tabProps,
        },
        {
          name: "third-party",
          component: ClientThirdPartyForm,
          props: this.tabProps,
        },
      ],
    };
  },
  mounted() {},
  methods: {
    portalActiveTab(active) {
      this.activeTab = active;
    },
    totalFees() {
      let total = 0;

      const changeTaxActive =
        this.tempContract[`${this.baseFinancesId}_change_tax_active`];
      const fineFeeActive =
        this.tempContract[`${this.baseFinancesId}_fine_fee_active`];
      const supplierFineFeeActive =
        this.tempContract[`${this.baseFinancesId}_supplier_fine_fee_active`];
      const verifyValues = [1, "1", true];

      if (verifyValues.includes(changeTaxActive)) {
        total += parse(
          this.tempContract[`${this.baseFinancesId}_change_tax_value`]
        );
      }

      if (verifyValues.includes(fineFeeActive)) {
        total += parse(
          this.tempContract[`${this.baseFinancesId}_fine_fee_value`]
        );
      }

      if (verifyValues.includes(supplierFineFeeActive)) {
        total += parse(
          this.tempContract[`${this.baseFinancesId}_supplier_fine_fee_value`]
        );
      }

      return total;
    },
    onPaymentChange(payments) {
      console.log(payments);
      this.paymentMethods = payments;
    },
  },
};
</script>

<style lang="scss" scoped>
.payments-options {
  color: #ffffff;
  padding: 10px 20px 5px;
  border: 1px solid #ddd !important;
  margin: 0 0 10px;
  border-radius: 6px;
  label {
    font-size: 13px;
    margin: 0;
    padding: 5px 0;
    width: 25%;
    font-weight: 500;
  }
}

.equality {
  font-size: 19px;
  margin-bottom: 0;
  position: relative;
  top: 4px;
  font-weight: 500;
  color: rgb(76, 56, 141);
}
</style>
