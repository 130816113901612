<template>
  <aRow>
    <aCol :span="24">
      <h4 class="subtitile">
        <aRow type="flex" justify="space-between">
          <aCol class="pt-5">Taxas Extras</aCol>
          <aCol>
            <aButton
              class="add-button color f12"
              size="small"
              @click="addTaxRow"
            >
              Adicionar
            </aButton>
          </aCol>
        </aRow>
      </h4>

      <aRow v-for="(tax, index) in taxesRows" :gutter="20" :key="index">
        <aCol :span="5">
          <HayaPriceField
            label="Taxas (R$)"
            :fieldKey="`${baseFinancesId}_tax_${tax.id}_value_new`"
            :form="form"
            :required="true"
            :preIcon="true"
          />
        </aCol>

        <aCol :span="8">
          <HayaSelectField
            label="Tipo"
            :fieldKey="`${baseFinancesId}_tax_${tax.id}_type_new`"
            :list="taxesType"
            optionFilterProp="txt"
            :required="true"
            :form="form"
            :allowClear="true"
            :showSearch="true"
          />
        </aCol>

        <aCol :span="7">
          <aFormItem>
            <aCheckbox
              :checked="
                tempContract[
                  `${baseFinancesId}_tax_${tax.id}_add_in_total_new`
                ] == 1
                  ? true
                  : false
              "
              class="f12 mt-5"
              v-decorator="[`${baseFinancesId}_tax_${tax.id}_add_in_total_new`]"
              >Somar no valor total</aCheckbox
            >
          </aFormItem>
        </aCol>

        <aCol :span="3">
          <aButton
            v-if="tax.id == taxesRows.length"
            class="mt-10"
            size="small"
            type="danger"
            shape="circle"
            icon="delete"
            @click="removeTaxRow(tax.id)"
          >
          </aButton>
        </aCol>
      </aRow>
    </aCol>

    <aCol :span="24">
      <h4 class="subtitile">
        <aRow type="flex" justify="space-between">
          <aCol class="pt-5">Incentivos</aCol>
          <aCol>
            <aButton
              class="add-button color f12"
              size="small"
              @click="addIncentiveRow"
            >
              Adicionar
            </aButton></aCol
          >
        </aRow>
      </h4>

      <aRow
        v-for="(incentive, index) in incentivesRows"
        :gutter="20"
        :key="index"
      >
        <aCol :span="5">
          <HayaSelectField
            label="Tipo"
            :fieldKey="`${baseFinancesId}_incentive_${incentive.id}_type_new`"
            :list="[
              { label: '%', value: '%' },
              { label: 'R$', value: 'R$' },
            ]"
            optionFilterProp="txt"
            :required="true"
            :form="form"
            :allowClear="true"
            :showSearch="true"
          />
        </aCol>

        <aCol :span="8">
          <aRow :gutter="8">
            <aCol :span="24">
              <aFormItem class="travel-input-outer">
                <label class="filled" for>Valor incentivo</label>
                <a-input
                  class="travel-input"
                  placeholder="Insira"
                  v-currency="{
                    currency: {
                      prefix: '',
                      suffix: '',
                    },
                    distractionFree: false,
                    valueAsInteger: false,
                    precision: 2,
                    autoDecimalMode: false,
                    allowNegative: false,
                  }"
                  v-decorator="[
                    `${baseFinancesId}_incentive_${incentive.id}_value_new`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                  :disabled="
                    form.getFieldValue(
                      `${baseFinancesId}_incentive_${incentive.id}_type_new`
                    )
                      ? false
                      : true
                  "
                  @blur="calcIncentivesPercentage(incentive.id)"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </aFormItem>
            </aCol>
          </aRow>
        </aCol>

        <aCol :span="7">
          <aFormItem>
            <aCheckbox
              :checked="
                tempContract[
                  `${baseFinancesId}_incentive_${incentive.id}_add_in_total_new`
                ] == 1
                  ? true
                  : false
              "
              v-decorator="[
                `${baseFinancesId}_incentive_${incentive.id}_add_in_total_new`,
              ]"
              >Somar no valor total</aCheckbox
            >
          </aFormItem>
        </aCol>

        <aCol :span="3">
          <aButton
            v-if="incentive.id == incentivesRows.length"
            class="mt-10"
            size="small"
            type="danger"
            shape="circle"
            icon="delete"
            @click="removeIncentiveRow(incentive.id)"
          >
          </aButton>
        </aCol>
      </aRow>
    </aCol>
  </aRow>
</template>

<script>
import HayaPriceField from "@/components/general/fields/HayaPriceField.vue";
import HayaSelectField from "@/components/general/fields/HayaSelectField.vue";

export default {
  name: "ContractProductsExtraTaxesAndIncentives",
  props: {
    form: Object,
    product: Object,
    tempContract: Object,
    baseId: String,
    baseFinancesId: String,
  },
  components: { HayaPriceField, HayaSelectField },
  data() {
    return {
      taxesRows: [],
      incentivesRows: [],
      taxesType: [],
    };
  },
  mounted() {
    this.$http
      .get(
        `/category-entry/list?page=1&per_page=10&category_id=3&order-by=name&order=ascend`
      )
      .then(({ data }) => {
        this.taxesType = data.data.map((item) => {
          return {
            value: item.name,
            label: item.name,
          };
        });
      });

    this.taxesRows = this.tempContract[`${this.baseFinancesId}_taxes_rows_new`]
      ? JSON.parse(this.tempContract[`${this.baseFinancesId}_taxes_rows_new`])
      : [];

    this.incentivesRows = this.tempContract[
      `${this.baseFinancesId}_incentives_rows_new`
    ]
      ? JSON.parse(
          this.tempContract[`${this.baseFinancesId}_incentives_rows_new`]
        )
      : [];

    setTimeout(() => {
      this.setProductFieldsValue();
    }, 100);
  },
  methods: {
    setProductFieldsValue() {
      this.taxesRows.forEach((tax) => {
        this.form.setFieldsValue({
          [`${this.baseFinancesId}_tax_${tax.id}_value_new`]:
            this.tempContract[`${this.baseFinancesId}_tax_${tax.id}_value_new`],
          [`${this.baseFinancesId}_tax_${tax.id}_type_new`]:
            this.tempContract[`${this.baseFinancesId}_tax_${tax.id}_type_new`],
        });
      });

      this.incentivesRows.forEach((i) => {
        this.form.setFieldsValue({
          [`${this.baseFinancesId}_incentive_${i.id}_value_new`]:
            this.tempContract[
              `${this.baseFinancesId}_incentive_${i.id}_value_new`
            ],
          [`${this.baseFinancesId}_incentive_${i.id}_type_new`]:
            this.tempContract[
              `${this.baseFinancesId}_incentive_${i.id}_type_new`
            ],
        });
      });
    },
    addIncentiveRow() {
      setTimeout(() => {
        this.incentivesRows.push({
          id: this.incentivesRows.length + 1,
        });

        this.tempContract[`${this.baseFinancesId}_incentives_rows_new`] =
          JSON.stringify(this.incentivesRows);
      }, 1);
    },
    removeIncentiveRow(id) {
      for (var i in this.incentivesRows) {
        if (this.incentivesRows[i].id == id) {
          this.incentivesRows.splice(i, 1);
          break;
        }
      }

      this.tempContract[`${this.baseFinancesId}_incentives_rows_new`] =
        JSON.stringify(this.incentivesRows);
    },
    addTaxRow() {
      setTimeout(() => {
        this.taxesRows.push({
          id: this.taxesRows.length + 1,
        });

        this.tempContract[`${this.baseFinancesId}_taxes_rows_new`] =
          JSON.stringify(this.taxesRows);
      }, 100);
    },
    removeTaxRow(id) {
      for (var i in this.taxesRows) {
        if (this.taxesRows[i].id == id) {
          this.taxesRows.splice(i, 1);
          break;
        }
      }

      this.tempContract[`${this.baseFinancesId}_taxes_rows_new`] =
        JSON.stringify(this.taxesRows);
    },
    calcIncentivesPercentage(incentiveId) {
      let percentage =
        this.tempContract[
          `${this.baseFinancesId}_incentive_${incentiveId}_value_new`
        ] ?? 0;
      let type =
        this.tempContract[
          `${this.baseFinancesId}_incentive_${incentiveId}_type_new`
        ];
      let totalValue = 0;

      if (percentage) {
        percentage = parseFloat(percentage.replace(",", "."));
        percentage = percentage / 100;
      } else {
        percentage = 0;
      }

      totalValue = this.tempContract[`${this.baseId}_total_price_new`];

      if (totalValue) {
        totalValue = parseFloat(totalValue.replace(",", "."));
      } else {
        totalValue = 0;
      }

      if (totalValue && percentage && type === "%") {
        this.tempContract[
          `${this.baseFinancesId}_incentive_${incentiveId}_percentage_value_new`
        ] = (percentage * totalValue).toFixed(2);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.subtitile
  text-transform: uppercase
  font-size: 12px
  padding: 5px 0
  border-bottom: 1px solid #ddd
  margin-bottom: 30px
</style>
