var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aRow',[_c('aCol',{attrs:{"span":24}},[_c('h4',{staticClass:"subtitile"},[_c('aRow',{attrs:{"type":"flex","justify":"space-between"}},[_c('aCol',{staticClass:"pt-5"},[_vm._v("Taxas Extras")]),_vm._v(" "),_c('aCol',[_c('aButton',{staticClass:"add-button color f12",attrs:{"size":"small"},on:{"click":_vm.addTaxRow}},[_vm._v("\n            Adicionar\n          ")])],1)],1)],1),_vm._v(" "),_vm._l((_vm.taxesRows),function(tax,index){return _c('aRow',{key:index,attrs:{"gutter":20}},[_c('aCol',{attrs:{"span":5}},[_c('HayaPriceField',{attrs:{"label":"Taxas (R$)","fieldKey":`${_vm.baseFinancesId}_tax_${tax.id}_value_new`,"form":_vm.form,"required":true,"preIcon":true}})],1),_vm._v(" "),_c('aCol',{attrs:{"span":8}},[_c('HayaSelectField',{attrs:{"label":"Tipo","fieldKey":`${_vm.baseFinancesId}_tax_${tax.id}_type_new`,"list":_vm.taxesType,"optionFilterProp":"txt","required":true,"form":_vm.form,"allowClear":true,"showSearch":true}})],1),_vm._v(" "),_c('aCol',{attrs:{"span":7}},[_c('aFormItem',[_c('aCheckbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([`${_vm.baseFinancesId}_tax_${tax.id}_add_in_total_new`]),expression:"[`${baseFinancesId}_tax_${tax.id}_add_in_total_new`]"}],staticClass:"f12 mt-5",attrs:{"checked":_vm.tempContract[
                `${_vm.baseFinancesId}_tax_${tax.id}_add_in_total_new`
              ] == 1
                ? true
                : false}},[_vm._v("Somar no valor total")])],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":3}},[(tax.id == _vm.taxesRows.length)?_c('aButton',{staticClass:"mt-10",attrs:{"size":"small","type":"danger","shape":"circle","icon":"delete"},on:{"click":function($event){return _vm.removeTaxRow(tax.id)}}}):_vm._e()],1)],1)})],2),_vm._v(" "),_c('aCol',{attrs:{"span":24}},[_c('h4',{staticClass:"subtitile"},[_c('aRow',{attrs:{"type":"flex","justify":"space-between"}},[_c('aCol',{staticClass:"pt-5"},[_vm._v("Incentivos")]),_vm._v(" "),_c('aCol',[_c('aButton',{staticClass:"add-button color f12",attrs:{"size":"small"},on:{"click":_vm.addIncentiveRow}},[_vm._v("\n            Adicionar\n          ")])],1)],1)],1),_vm._v(" "),_vm._l((_vm.incentivesRows),function(incentive,index){return _c('aRow',{key:index,attrs:{"gutter":20}},[_c('aCol',{attrs:{"span":5}},[_c('HayaSelectField',{attrs:{"label":"Tipo","fieldKey":`${_vm.baseFinancesId}_incentive_${incentive.id}_type_new`,"list":[
            { label: '%', value: '%' },
            { label: 'R$', value: 'R$' },
          ],"optionFilterProp":"txt","required":true,"form":_vm.form,"allowClear":true,"showSearch":true}})],1),_vm._v(" "),_c('aCol',{attrs:{"span":8}},[_c('aRow',{attrs:{"gutter":8}},[_c('aCol',{attrs:{"span":24}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled",attrs:{"for":""}},[_vm._v("Valor incentivo")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency",value:({
                  currency: {
                    prefix: '',
                    suffix: '',
                  },
                  distractionFree: false,
                  valueAsInteger: false,
                  precision: 2,
                  autoDecimalMode: false,
                  allowNegative: false,
                }),expression:"{\n                  currency: {\n                    prefix: '',\n                    suffix: '',\n                  },\n                  distractionFree: false,\n                  valueAsInteger: false,\n                  precision: 2,\n                  autoDecimalMode: false,\n                  allowNegative: false,\n                }"},{name:"decorator",rawName:"v-decorator",value:([
                  `${_vm.baseFinancesId}_incentive_${incentive.id}_value_new`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  `${baseFinancesId}_incentive_${incentive.id}_value_new`,\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira","disabled":_vm.form.getFieldValue(
                    `${_vm.baseFinancesId}_incentive_${incentive.id}_type_new`
                  )
                    ? false
                    : true},on:{"blur":function($event){return _vm.calcIncentivesPercentage(incentive.id)}}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":7}},[_c('aFormItem',[_c('aCheckbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `${_vm.baseFinancesId}_incentive_${incentive.id}_add_in_total_new`,
            ]),expression:"[\n              `${baseFinancesId}_incentive_${incentive.id}_add_in_total_new`,\n            ]"}],attrs:{"checked":_vm.tempContract[
                `${_vm.baseFinancesId}_incentive_${incentive.id}_add_in_total_new`
              ] == 1
                ? true
                : false}},[_vm._v("Somar no valor total")])],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":3}},[(incentive.id == _vm.incentivesRows.length)?_c('aButton',{staticClass:"mt-10",attrs:{"size":"small","type":"danger","shape":"circle","icon":"delete"},on:{"click":function($event){return _vm.removeIncentiveRow(incentive.id)}}}):_vm._e()],1)],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }