<template>
  <section class="product-cancellation">
    <div class="travel-tabs">
      <div class="tab-icons">
        <div
          v-if="cancelSpecificProducts"
          class="tab grayscale"
          :class="activeTab === 'product' ? 'active' : ''"
          @click="onClickActiveTab('product')"
        >
          <img
            src="@/assets/images/dashboard/contracts/services-ico.png"
            alt="img"
            height="30"
          />
        </div>
        <div
          class="tab grayscale"
          :class="activeTab === 'financial' ? 'active' : ''"
          @click="onClickActiveTab('financial')"
        >
          <img
            src="@/assets/images/dashboard/contracts/financial-icon.png"
            alt="img"
            height="30"
          />
        </div>
        <div
          class="tab grayscale"
          :class="activeTab === 'incomings' ? 'active' : ''"
          @click="onClickActiveTab('incomings')"
        >
          <img
            src="@/assets/images/dashboard/contracts/incomings-icon.png"
            alt="img"
            height="30"
          />
        </div>
      </div>
      <div class="tab-content">
        <aRow>
          <aCol class="inner-content" :span="24">
            <template v-if="activeTab === 'product'">
              <div class="tab-header">Produto</div>
              <div class="pr-10 pt-10">
                <ContractModalServiceRow
                  :product="productToCancel"
                  :tempContract="tempContract"
                  :allContractData="allContractData"
                  @onChangeCancelProductData="onChangeCancelProductData"
                  type="edit"
                />
              </div>
            </template>
            <template v-if="activeTab === 'financial'">
              <div class="tab-header">Financeiro</div>
              <div class="pr-10 pt-10">
                <ContractCancelServiceFinancialForm
                  :product="productToCancel"
                  :tempContract="tempContract"
                  :allContractData="allContractData"
                  @onChangeCancelProductData="onChangeCancelProductData"
                />
              </div>
            </template>
            <template v-if="activeTab === 'incomings'">
              <div class="tab-header">Recebimentos</div>
              <div class="pr-10 pt-10">
                <aForm @submit.prevent="onSubmitForm" :form="form">
                  <ContractPaymentsReceivable
                    :form="form"
                    :tempContract="tempContract"
                    keyPrefix=""
                    keySuffix="_new"
                    :baseId="`service_${this.productToCancel.id}`"
                    :baseFinancesId="`contract_finances_service_${this.productToCancel.id}`"
                  />

                  <div class="a-right">
                    <aButton
                      class="page-button f12 mb-30"
                      type="primary"
                      html-type="submit"
                      :loading="loadingSubmit"
                      :disabled="loadingSubmit"
                    >
                      Salvar dados
                    </aButton>
                  </div>
                </aForm>
              </div>
            </template>
          </aCol>

          <aCol v-if="cancelSpecificProducts" :span="24">
            <aRow class="footer" :gutter="20">
              <aCol class="disclaimer f10" :span="16">
                <b>Atenção:</b> Ao prosseguir com o cancelamento desta venda,
                uma carta de crédito será automaticamente gerada para o cliente.
              </aCol>
              <aCol class="a-right" :span="8">
                <aButton
                  class="f12"
                  type="danger"
                  size="small"
                  :disabled="disableCancelProduct"
                >
                  <img
                    class="mr-10"
                    src="@/assets/images/dashboard/contracts/cancel-sale.png"
                    alt="icon"
                    width="15"
                    v-if="!disableCancelProduct"
                  />
                  Cancelar Produto</aButton
                >
              </aCol>
            </aRow>
          </aCol>
        </aRow>
        <div id="live-debug">
          <json-viewer
            :value="tempContract"
            :show-array-index="false"
            :expand-depth="1"
          ></json-viewer>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";
import ContractModalServiceRow from "../sections/ContractModalServiceRow.vue";
import ContractCancelServiceFinancialForm from "../sections/ContractCancelServiceFinancialForm.vue";
import ContractPaymentsReceivable from "../sections/ContractPaymentsReceivable.vue";

export default {
  name: "CancelServiceModal",
  props: {
    productToCancel: Object,
    tempContract: Object,
    cancelSpecificProducts: Boolean,
    allContractData: Object,
  },
  components: {
    ContractModalServiceRow,
    ContractCancelServiceFinancialForm,
    ContractPaymentsReceivable,
  },
  mixins: [formatThings],
  data() {
    return {
      activeTab: "financial",
      disableCancelProduct: true,
      loadingSubmit: false,
    };
  },
  mounted() {
    if (this.cancelSpecificProducts) this.activeTab = "product";
  },
  methods: {
    onChangeCancelProductData(tempContract) {
      this.$emit("onChangeCancelProductData", tempContract);
    },
    onClickActiveTab(active) {
      this.activeTab = active;
    },
  },
};
</script>

<style lang="scss" scoped>
$border-color: #e0e0e0;
$btn-color: #ff4545;

.data-row {
  background: #fff;
  padding: 10px;
  border: 1px solid #ddd !important;
  margin: 0 0 10px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  .label {
    display: block;
    font-size: 12px;
    font-weight: 500;
    color: #aaa;
  }
}

.product-cancellation {
  .travel-tabs {
    height: 642px;
    .tab-icons {
      float: left;
      border-right: 1px solid $border-color;
      width: 75px;
      height: 642px;
      text-align: center;
      .tab {
        float: left;
        height: 60px;
        width: 100%;
        line-height: 56px;
        cursor: pointer;
        transition: 0.6s;
        opacity: 0.6;
        border-bottom: 1px solid #e0e0e0;
      }
      & :hover,
      .active {
        filter: none;
        -webkit-filter: grayscale(0);
        -webkit-filter: grayscale(0%);
        opacity: 1;
      }
    }
    .tab-content {
      position: relative;
      height: 642px;
      margin-left: 75px;
      padding: 0 10px;
      .inner-content {
        overflow: auto;
        height: 595px;
        width: 101.4%;
        .tab-header {
          border-bottom: 1px solid $border-color;
          padding: 10px 0;
          font-weight: 600;
        }
      }
      .footer {
        display: flex;
        border-top: 1px solid $border-color;
        padding: 10px 0;
        .disclaimer {
          color: #3c3c3c;
          font-weight: 500;
          b {
            color: $btn-color;
          }
        }

        button {
          padding: 0px 20px;
          font-weight: 500;
        }
      }
    }
  }
}
</style>
