<template>
  <aRow>
    <aCol
      class="content-row"
      v-for="(item, index) in theList().arr"
      :key="index"
      :span="24"
    >
      <aRow class="tab-header" type="flex" justify="space-between">
        <aCol>
          <h3 class="cprimary">{{ item.id }} - {{ item.name }}</h3>
        </aCol>
        <aCol>
          <a v-if="cancellationMode" @click="onClickOpenCancelModal(item)">
            <img
              class="mr-5"
              src="@/assets/images/dashboard/contracts/cancel-product.png"
              width="20"
              alt="icon"
            />
          </a>
        </aCol>
      </aRow>

      <ContractModalServiceRow
        type="view"
        :product="item"
        :tempContract="tempContract"
        :allContractData="allContractData"
      />
    </aCol>

    <aModal
      class="product-cancel-modal"
      :title="false"
      :visible="openCancelModal"
      :footer="false"
      :width="800"
      @cancel="onClickCloseCancelModal"
    >
      <CancelServiceModal
        v-if="openCancelModal"
        :productToCancel="productToCancel"
        :tempContract="tempContract"
        :allContractData="allContractData"
        :cancelSpecificProducts="cancelSpecificProducts"
        @onChangeCancelProductData="onChangeCancelProductData"
      />
    </aModal>
  </aRow>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";
import ContractModalServiceRow from "@/components/contracts/sections/ContractModalServiceRow.vue";
import CancelServiceModal from "../modals/CancelServiceModal.vue";

export default {
  name: "ContractServiceDetails",
  props: {
    tempContract: Object,
    allContractData: Object,
    cancellationMode: Boolean,
    cancelSpecificProducts: Boolean,
  },
  components: { ContractModalServiceRow, CancelServiceModal },
  mixins: [formatThings],
  data() {
    return {
      productToCancel: false,
      openCancelModal: false,
    };
  },
  methods: {
    onChangeCancelProductData(tempContract) {
      this.$emit("onChangeCancelProductData", tempContract);
    },
    onClickOpenCancelModal(product) {
      this.productToCancel = product;
      this.openCancelModal = true;
    },
    onClickCloseCancelModal() {
      this.productToCancel = {};
      this.openCancelModal = false;
    },
    thePassenger(id) {
      let travellers = JSON.parse(this.tempContract.travellers_rows);
      let theTraveller = {};

      travellers.forEach((traveller) => {
        if (traveller.id == id) {
          theTraveller.name = `${
            this.tempContract[`traveller_${traveller.id}_first_name`]
          } ${this.tempContract[`traveller_${traveller.id}_last_name`]}`;

          theTraveller.birthday = `${
            this.tempContract[`traveller_${traveller.id}_birthday`]
          }`;

          if (this.tempContract[`traveller_${traveller.id}_gender`]) {
            theTraveller.gender = `${
              this.tempContract[`traveller_${traveller.id}_gender`]
            }`;
          }
        }
      });

      return theTraveller;
    },
    theList() {
      let arr = [];
      let contractedServices = JSON.parse(
        this.tempContract.contracted_services
      );

      if (contractedServices.includes("service")) {
        JSON.parse(this.tempContract["service_rows"]).forEach((service) => {
          let serviceTravellers = [];
          service.travellers.forEach((traveller) => {
            serviceTravellers.push(
              this.thePassenger(
                this.tempContract[
                  `service_${service.id}_traveller_${traveller.id}`
                ]
              )
            );
          });
          arr.push({
            id: service.id,
            name: this.tempContract[`service_${service.id}_name`],
            type: this.tempContract[`service_${service.id}_type`],
            locator: this.tempContract[`service_${service.id}_locator`],
            checkin: this.tempContract[`service_${service.id}_checkin`],
            checkout: this.tempContract[`service_${service.id}_checkout`],
            serviceTravellers,
          });
        });
      }

      return { arr };
    },
  },
};
</script>

<style lang="sass" scoped>
.content-row
  background: #FFF
  padding: 10px
  border: 1px solid #ddd !important
  margin: 0 0 10px
  border-radius: 6px
  font-size: 13px
  font-weight: 500
  .tab-header
    margin-bottom: 15px
    color: #be4178
    text-transform: uppercase
    border-bottom: 1px solid #ececec
    h3
      font-size: 14px
      font-weight: 600
</style>
